<template>
    <div>
        <el-form  :model="config" label-width="120px">
            <el-form-item label="原始密码">
                <el-input type="password" v-model="config.upwd1" style="width: 500px"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
                <el-input type="password" v-model="config.upwd2" style="width: 500px"></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
                <el-input type="password" v-model="config.upwd3" style="width: 500px"></el-input>
            </el-form-item>

            <el-form-item label="">
                <el-button type="primary" @click="save">确 定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<style>

</style>
<script>
    export default {
        data(){
          return {
            config:{
                upwd1:'',
                upwd2:'',
                upwd3:'',
            }
          }
        },
        mounted(){
            document.title='密码修改'
        },
        methods:{
            save(){
                let _this = this
                if(this.config.upwd1==''){return _this.$message.error('请输入原始密码')}
                if(this.config.upwd2==''){return _this.$message.error('请输入新密码')}
                if(this.config.upwd2!=this.config.upwd3){return _this.$message.error('两次密码输入不一致')}

                this.$post('/pc/globle/pcUpdateUpwd',_this.config,function (res) {
                    let result =res.data
                    if(result.code==200){
                        return _this.$message.success(result.msg)
                    }
                      return  _this.$message.error(result.msg)
                })

            }
        }
    }
</script>
